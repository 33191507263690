<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('aqvemissionfactor.title')">
					<v-layout wrap>
						<v-flex xs12 md8>
							<pui-text-field
								:label="$t('aqvemissionfactor.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvemissionfactor.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqversionemissionfactorid"
								:label="this.$t('aqvemissionfactor.versionemissionfactorname')"
								:placeholder="this.$t('aqvemissionfactor.phversionemissionfactorname')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqversionemissionfactor"
								:modelFormMapping="{ aqversionemissionfactorid: 'versionemissionfactorid' }"
								:itemsToSelect="versionemissionfactorItemsToSelect"
								itemValue="aqversionemissionfactorid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqenginetypesid"
								:label="this.$t('aqvemissionfactor.enginetype')"
								:placeholder="this.$t('aqvemissionfactor.phenginetype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqenginetypes"
								:modelFormMapping="{ aqenginetypesid: 'enginetype' }"
								:itemsToSelect="enginetypesItemsToSelect"
								itemValue="aqenginetypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqtiersid"
								:label="this.$t('aqvemissionfactor.tier')"
								:placeholder="this.$t('aqvemissionfactor.phtier')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqtiers"
								:modelFormMapping="{ aqtiersid: 'tier' }"
								:itemsToSelect="tiersItemsToSelect"
								itemValue="aqtiersid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqenginespeedsid"
								:label="this.$t('aqvemissionfactor.enginespeed')"
								:placeholder="this.$t('aqvemissionfactor.phenginespeed')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqenginespeeds"
								:modelFormMapping="{ aqenginespeedsid: 'enginespeed' }"
								:itemsToSelect="enginespeedsItemsToSelect"
								itemValue="aqenginespeedsid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqfueltypesid"
								:label="this.$t('aqvemissionfactor.fueltype')"
								:placeholder="this.$t('aqvemissionfactor.phfueltype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqfueltypes"
								:modelFormMapping="{ aqfueltypesid: 'fueltype' }"
								:itemsToSelect="fueltypesItemsToSelect"
								itemValue="aqfueltypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqpollutantsid"
								:label="this.$t('aqvemissionfactor.pollutant')"
								:placeholder="this.$t('aqvemissionfactor.phpollutant')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqpollutants"
								:modelFormMapping="{ aqpollutantsid: 'pollutant' }"
								:itemsToSelect="pollutantsItemsToSelect"
								itemValue="aqpollutantsid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvemissionfactor.technology')"
								v-model="model.technology"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvemissionfactor.mainemissionfactor')"
								v-model="model.mainemissionfactor"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvemissionfactor.auxemissionfactor')"
								v-model="model.auxemissionfactor"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md12>
							<pui-text-area
								v-model="model.description"
								:label="$t('aqvemissionfactor.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('aqvemissionfactor.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'aqvemissionfactorform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'aqvemissionfactor',
			model: 'aqvemissionfactor'
		};
	},
	computed: {
		versionemissionfactorItemsToSelect() {
			return [{ aqversionemissionfactorid: this.model.aqversionemissionfactorid }];
		},
		enginetypesItemsToSelect() {
			return [{ enginetypesid: this.model.enginetypesid }];
		},
		tiersItemsToSelect() {
			return [{ aqtiersid: this.model.aqtiersid }];
		},
		enginespeedsItemsToSelect() {
			return [{ aqenginespeedsid: this.model.aqenginespeedsid }];
		},
		fueltypesItemsToSelect() {
			return [{ aqfueltypesid: this.model.aqversionemissionfactorid }];
		},
		pollutantsItemsToSelect() {
			return [{ aqpollutantsid: this.model.aqpollutantsid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {}
};
</script>
